/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stack, Banner, BodyText, Card} from '@servicetitan/design-system';
import {ImageBlock, Link} from 'components';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    pre: "pre",
    code: "code",
    h3: "h3",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong",
    hr: "hr"
  }, _provideComponents(), props.components);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "overview",
    style: {
      position: "relative"
    }
  }, "Overview", React.createElement(_components.a, {
    href: "#overview",
    "aria-label": "overview permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Banner, {
    title: "Kendo Source"
  }, "This component is powered under the hood by the ", React.createElement(Link, {
    href: "https://www.telerik.com/kendo-react-ui/components/grid/"
  }, "Kendo React Data Grid"), ". Features available there can be used in the Anvil table."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Table\n\tstriped={false}\n\tdata={[\n\t\t{ id: 1, name: 'Chai', price: 18 },\n\t\t{ id: 2, name: 'Chang', price: 19 },\n\t\t{ id: 3, name: 'Aniseed Syrup', price: 10 },\n\t\t{ id: 4, name: 'Chef Anton’s Cajun Seasoning', price: 22 },\n\t]}\n>\n\t<TableColumn field=\"id\" title=\"Id\" width=\"60px\" />\n\t<TableColumn field=\"name\" title=\"Name\" />\n\t<TableColumn field=\"price\" title=\"Price\" width=\"180px\" format=\"{0:c}\" />\n</Table>\n")), "\n", React.createElement(_components.h1, {
    id: "anatomy",
    style: {
      position: "relative"
    }
  }, "Anatomy", React.createElement(_components.a, {
    href: "#anatomy",
    "aria-label": "anatomy permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(ImageBlock, {
    src: "/images/table/table_anatomy.png"
  }), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "p-1 p-t-2"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(BodyText, null, React.createElement("div", null, "1. Header row and cell"), React.createElement("div", null, "2. Table row and cell"), React.createElement("div", null, "3. Pagination"), React.createElement("div", null, "4. Selection header (locked column)"), React.createElement("div", null, "5. Zebra stripes (even rows)"))), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(BodyText, null, React.createElement("div", null, "6. Action column (locked column)"), React.createElement("div", null, "7. Row actions"), React.createElement("div", null, "8. Right aligned column"), React.createElement("div", null, "9. Selected row")))), "\n", React.createElement(_components.h3, {
    id: "table-spacing",
    style: {
      position: "relative"
    }
  }, "Table Spacing", React.createElement(_components.a, {
    href: "#table-spacing",
    "aria-label": "table spacing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Cell padding is 12px. Total height of a row is based on the tallest element in a row."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_spacing_selection.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_spacing_action.png"
  }))), "\n", React.createElement(_components.h1, {
    id: "options",
    style: {
      position: "relative"
    }
  }, "Options", React.createElement(_components.a, {
    href: "#options",
    "aria-label": "options permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "zebra-stripes",
    style: {
      position: "relative"
    }
  }, "Zebra Stripes", React.createElement(_components.a, {
    href: "#zebra-stripes",
    "aria-label": "zebra stripes permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 4,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_zebra.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(_components.h3, {
    id: "zebra-stripes-should-be-used-when",
    style: {
      position: "relative"
    }
  }, "Zebra stripes should be used when", React.createElement(_components.a, {
    href: "#zebra-stripes-should-be-used-when",
    "aria-label": "zebra stripes should be used when permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Tables have many read-only columns"), "\n", React.createElement(_components.li, null, "Scanning and comparing table items is important"), "\n"), "\n", React.createElement(_components.h3, {
    id: "zebra-stripes-should-not-be-used-when",
    style: {
      position: "relative"
    }
  }, "Zebra stripes should not be used when", React.createElement(_components.a, {
    href: "#zebra-stripes-should-not-be-used-when",
    "aria-label": "zebra stripes should not be used when permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Tables are simple"), "\n", React.createElement(_components.li, null, "Tables are very complex, with multiple interactive elements"), "\n", React.createElement(_components.li, null, "The page UI it’s on is highly complex"), "\n"), "\n", React.createElement(_components.h2, {
    id: "table-headers--columns",
    style: {
      position: "relative"
    }
  }, "Table Headers & Columns", React.createElement(_components.a, {
    href: "#table-headers--columns",
    "aria-label": "table headers  columns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "locked-columns",
    style: {
      position: "relative"
    }
  }, "Locked Columns", React.createElement(_components.a, {
    href: "#locked-columns",
    "aria-label": "locked columns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(ImageBlock, {
    src: "/images/table/table_locked.png"
  }), "\n", React.createElement(_components.p, null, "Locked columns: Checkbox, Name Cell, Actions"), "\n", React.createElement("div", {
    className: "m-t-4"
  }), "\n", React.createElement(ImageBlock, {
    src: "/images/table/table_locked_vert.png"
  }), "\n", React.createElement(_components.p, null, "Darker borders are applied to locked columns when non-locked cells also have vertical\nborders."), "\n", React.createElement("div", {
    className: "m-t-4"
  }), "\n", React.createElement(_components.p, null, "Locked columns allow users to horizontally scroll a table and maintain viewing of certain columns. They are visually represented with a vertical border."), "\n", React.createElement(_components.h3, {
    id: "when-to-use-locked-columns",
    style: {
      position: "relative"
    }
  }, "When to use locked columns:", React.createElement(_components.a, {
    href: "#when-to-use-locked-columns",
    "aria-label": "when to use locked columns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When there are 5+ table columns and/or horizontal scrolling is expected."), "\n", React.createElement(_components.li, null, "If the table has checkboxes and is expected to scroll horizontally, the checkbox plus a key identifier should be locked, and be the left-most column."), "\n", React.createElement(_components.li, null, "On row-level actions. This should always be the right-most column."), "\n", React.createElement(_components.li, null, "For other columns, if they are essential to maintain while scrolling, such as a name or ID."), "\n"), "\n", React.createElement(_components.h3, {
    id: "what-to-avoid-when-using-locked-columns",
    style: {
      position: "relative"
    }
  }, "What to avoid when using locked columns:", React.createElement(_components.a, {
    href: "#what-to-avoid-when-using-locked-columns",
    "aria-label": "what to avoid when using locked columns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Locking more than 4 columns. Locking should only be used when it is essential."), "\n", React.createElement(_components.li, null, "Locking columns in the middle of the table. Only the left and right edges should be locked."), "\n", React.createElement(_components.li, null, "Locking a checkbox column in isolation. Pair it with a key identifier."), "\n"), "\n", React.createElement(_components.h3, {
    id: "resizing",
    style: {
      position: "relative"
    }
  }, "Resizing", React.createElement(_components.a, {
    href: "#resizing",
    "aria-label": "resizing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 4,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_resizing.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(_components.p, null, "When resize is enabled, a user will also be able to double click the grabbing area\nto auto resize the column to fit its content."), "\n", React.createElement(_components.h3, {
    id: "sortable",
    style: {
      position: "relative"
    }
  }, "Sortable", React.createElement(_components.a, {
    href: "#sortable",
    "aria-label": "sortable permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_sort_none.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_sort_asc.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_sort_dsc.png"
  }))), "\n", React.createElement(_components.h3, {
    id: "multi-column-headers",
    style: {
      position: "relative"
    }
  }, "Multi-Column Headers", React.createElement(_components.a, {
    href: "#multi-column-headers",
    "aria-label": "multi column headers permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In addition to text, the Table accepts many different types of content. The following are some examples, along with our recommendations of use."), "\n", React.createElement(ImageBlock, {
    src: "/images/table/table_multi_column.png"
  }), "\n", React.createElement(_components.p, null, "When using multi-column headers, apply a bottom alignment to headers that don’t have\nmultiple rows."), "\n", React.createElement(_components.h2, {
    id: "custom-cell-content",
    style: {
      position: "relative"
    }
  }, "Custom Cell Content", React.createElement(_components.a, {
    href: "#custom-cell-content",
    "aria-label": "custom cell content permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(ImageBlock, {
    src: "/images/table/table_custom_do.png",
    suggest: "do"
  }), "\n", React.createElement(Banner, {
    status: "success",
    title: "Do"
  }, React.createElement(_components.p, null, "Many of the recommended custom cell stylings for the table. Additional\noptions may also be used beyond this set.")), "\n", React.createElement(ImageBlock, {
    src: "/images/table/table_custom_caution.png",
    suggest: "caution"
  }), "\n", React.createElement(Banner, {
    status: "warning",
    title: "Caution"
  }, React.createElement(_components.p, null, "We caution using bold or headline styling. This styling may overpower a\npage, particularly on busy pages. Use only when it’s essential to call out\nthe cell, that is otherwise not happening at the normal font weight. Text\ncolor may be used as long as it maintains a color contrast above 4.5:1 on\nthe background colors the cell may be exposed to.")), "\n", React.createElement(ImageBlock, {
    src: "/images/table/table_custom_dont.png",
    suggest: "dont"
  }), "\n", React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }, React.createElement(_components.p, null, "These combinations specifically are not recommended for use.")), "\n", React.createElement(_components.h2, {
    id: "pagination",
    style: {
      position: "relative"
    }
  }, "Pagination", React.createElement(_components.a, {
    href: "#pagination",
    "aria-label": "pagination permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 4,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_pagination.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pagination should be used in most contexts, when 1 or more entry is used. If it’s known ahead of time a table will only use a few entries, a pagination can be omitted."), "\n", React.createElement(_components.li, null, "A default of 25 items per page is recommended, with increments of 10, 25, 50, and 100."), "\n", React.createElement(_components.li, null, "A lower default, such as 5 or 10, can be used when multiple tables are on the page."), "\n", React.createElement(_components.li, null, "User control of items per page should always be used."), "\n", React.createElement(_components.li, null, "Empty states, whether by no entries, or by filtering with no results, should not use the pagination."), "\n"), "\n", React.createElement(_components.h2, {
    id: "empty-state",
    style: {
      position: "relative"
    }
  }, "Empty State", React.createElement(_components.a, {
    href: "#empty-state",
    "aria-label": "empty state permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(ImageBlock, {
    src: "/images/table/table_empty_state.png"
  }), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Refer to the ", React.createElement(_components.a, {
    href: "/patterns/empty-states/"
  }, "Empty State pattern"), " on usage and content guidance within a table."), "\n", React.createElement(_components.li, null, "Avoid using the pagination when an empty state is used."), "\n"), "\n", React.createElement(_components.h2, {
    id: "table-actions",
    style: {
      position: "relative"
    }
  }, "Table Actions", React.createElement(_components.a, {
    href: "#table-actions",
    "aria-label": "table actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "row-level-actions",
    style: {
      position: "relative"
    }
  }, "Row-level Actions", React.createElement(_components.a, {
    href: "#row-level-actions",
    "aria-label": "row level actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    direction: "column",
    spacing: 4
  }, React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_row_actions.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(BodyText, null, React.createElement(_components.p, null, "Row-level actions refer to actions to be taken on an entry as a whole."), React.createElement("ul", null, React.createElement("div", null, React.createElement("li", null, "Row-actions should be the right-most column, without text in the header column. The action column should always be locked.")), React.createElement("div", null, React.createElement("li", null, "Actions should use extra small Buttons.")))))), React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_row_action_text.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/dont_table_row_style.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }, React.createElement(_components.p, null, "Don’t using other button styles other than the ones used above."))), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/dont_table_row_mix_icons.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }, React.createElement(_components.p, null, "Don’t mix different row actions. If some rows cannot perform the\nsame action another row can, disable the button instead."))), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/dont_table_row_hover.png",
    suggest: "dont"
  }), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }, React.createElement(_components.p, null, "Show actions only on hover. Row actions should always be visible."))), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }))), "\n", React.createElement(_components.h3, {
    id: "cell-level-actions-and-links",
    style: {
      position: "relative"
    }
  }, "Cell-level Actions and Links", React.createElement(_components.a, {
    href: "#cell-level-actions-and-links",
    "aria-label": "cell level actions and links permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    direction: "column",
    spacing: 4
  }, React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_cell_links.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(BodyText, null, "In general, Links, rather than Buttons, should be used, as most of these actions are navigational."))), React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_cell_actions.png"
  }), React.createElement(BodyText, null, "Cell-level actions can be used, but rarely. ")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_cell_mix.png"
  }), React.createElement(BodyText, null, "Under special circumstances, links and actions can be mixed. Actions in this case are used in placed of a null value.")), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }))), "\n", React.createElement(_components.h2, {
    id: "inline-edit",
    style: {
      position: "relative"
    }
  }, "Inline Edit", React.createElement(_components.a, {
    href: "#inline-edit",
    "aria-label": "inline edit permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 4,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_inline_ex.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When using inline edit, apply vertical borders to all cells so that users can more easily associate the icon with the cell."), "\n", React.createElement(_components.li, null, "Have the save/cancel action placement be in the bottom right to avoid page clipping, as most tables will have a footer to handle overlay spillover."), "\n"), "\n", React.createElement(_components.h2, {
    id: "bulk-actions--selection",
    style: {
      position: "relative"
    }
  }, "Bulk Actions & Selection", React.createElement(_components.a, {
    href: "#bulk-actions--selection",
    "aria-label": "bulk actions  selection permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(ImageBlock, {
    src: "/images/table/table_bulk_main.png"
  }), "\n", React.createElement(_components.p, null, "The most common bulk experience. The bulk actions appear only when a selection is\nin progress. Upon performing a bulk action, deselect the table and optionally display\na Toast."), "\n", React.createElement(Stack, {
    direction: "column",
    spacing: 4
  }, React.createElement(Stack, {
    spacing: 3,
    className: "p-t-4"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement("h3", null, "Bulk action button count and styling"), React.createElement(BodyText, null, React.createElement(_components.p, null, "Surface 1 or 2 important bulk actions when in the selection\nstate. When using 3+ actions, have the less important and\ndestructive actions should be inside the view more button.")), React.createElement(BodyText, null, React.createElement(_components.p, null, "When using bulk actions, stick to the default button. Do not use\nother styles, including for very important or destructive\nactions.")))), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_bulk_action_count.png",
    suggest: "do"
  }), React.createElement(Banner, {
    status: "success",
    title: "Do"
  }, React.createElement(_components.p, null, "Button styling and placement for 1, 2, and 3+ bulk actions.")))), React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement("h3", null, "Select All ‘X’ Flow"), React.createElement(BodyText, null, React.createElement(_components.p, null, "Sometimes a user needs to select the entire table data set,\nrather than just all the entries on the page. When this\nfunctionality is needed, provide a “Select All ‘X’” Button after\nthe bulk actions. Technical constraints should be considered\nwhen using this, such as in the case of many thousands or\nunknown number of results.")))), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_bulk_select_all.png"
  }), React.createElement(BodyText, null, React.createElement(_components.p, null, "The microflow to select all. Once all items are selected, a “Clear\nSelection” option replaces the previous action.", ' ')))), React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_bulk_no_select_all.png"
  }), React.createElement(BodyText, null, React.createElement(_components.p, null, "A table can also lack the select all feature, either for UX or\ntechnical reasons.")))), React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement("h3", null, "Replacing the utilities above a table with bulk actions"), React.createElement(BodyText, null, React.createElement(_components.p, null, "It is common for related table elements to be right above the\ntable. When bulk editing occurs, we generally recommend\nreplacing these actions with the bulk controls.", ' ')), React.createElement(BodyText, null, React.createElement(_components.p, null, "Preserving the other controls is acceptable, but be mindful of\nany UX troubles and technical constraints that may arise (e.g. a\ntable search while items are selected).")))), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_bulk_replace.png",
    suggest: "do"
  }), React.createElement(Banner, {
    status: "success",
    title: "Do"
  }, React.createElement(_components.p, null, "The preferred method: replace table header controls with bulk\ncontrols.")))), React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_bulk_both.png",
    suggest: "caution"
  }), React.createElement(Banner, {
    status: "warning",
    title: "Caution"
  }, React.createElement(_components.p, null, "Only use when there is a clear user benefit to keeping both, as this\nadds technical and user complexity."))))), "\n", React.createElement(_components.h1, {
    id: "interactive-states",
    style: {
      position: "relative"
    }
  }, "Interactive States", React.createElement(_components.a, {
    href: "#interactive-states",
    "aria-label": "interactive states permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "hover",
    style: {
      position: "relative"
    }
  }, "Hover", React.createElement(_components.a, {
    href: "#hover",
    "aria-label": "hover permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(ImageBlock, {
    src: "/images/table/table_hover.png"
  }), "\n", React.createElement(_components.h2, {
    id: "selected",
    style: {
      position: "relative"
    }
  }, "Selected", React.createElement(_components.a, {
    href: "#selected",
    "aria-label": "selected permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 4,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_selected.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(_components.h2, {
    id: "loading",
    style: {
      position: "relative"
    }
  }, "Loading", React.createElement(_components.a, {
    href: "#loading",
    "aria-label": "loading permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 4,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_loading.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(_components.h2, {
    id: "errors--warnings",
    style: {
      position: "relative"
    }
  }, "Errors & Warnings", React.createElement(_components.a, {
    href: "#errors--warnings",
    "aria-label": "errors  warnings permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 4,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_error_warning.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(_components.p, null, "Direct error & warning style does not exist on tables. The suggested styling is to use an inline error and warning text in individual cells."), "\n", React.createElement(_components.h1, {
    id: "when-to-use",
    style: {
      position: "relative"
    }
  }, "When to Use", React.createElement(_components.a, {
    href: "#when-to-use",
    "aria-label": "when to use permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "tables-vs-list-views",
    style: {
      position: "relative"
    }
  }, "Tables vs List Views", React.createElement(_components.a, {
    href: "#tables-vs-list-views",
    "aria-label": "tables vs list views permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A list view is not a specific component, but a layout option for displaying information. Many lists will exist within Cards in the desktop environment."), "\n", React.createElement(_components.h3, {
    id: "example-a-card-list-as-a-navigational-item",
    style: {
      position: "relative"
    }
  }, "Example: A Card List as a navigational item", React.createElement(_components.a, {
    href: "#example-a-card-list-as-a-navigational-item",
    "aria-label": "example a card list as a navigational item permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack direction=\"column\" spacing={2}>\n\t<Card>\n\t\t<Stack justifyContent=\"space-between\" alignItems=\"center\">\n\t\t\t<Stack direction=\"column\">\n\t\t\t\t<Headline size=\"small\">Email configuration</Headline>\n\t\t\t\t<BodyText size=\"small\">\n\t\t\t\t\tConfigure what will appear in your email campaigns.\n\t\t\t\t</BodyText>\n\t\t\t</Stack>\n\t\t\t<Button href=\"#\" iconName=\"chevron_right\" fill=\"subtle\" />\n\t\t</Stack>\n\t</Card>\n\t<Card>\n\t\t<Stack justifyContent=\"space-between\" alignItems=\"center\">\n\t\t\t<Stack direction=\"column\">\n\t\t\t\t<Headline size=\"small\">Opt-In / Out</Headline>\n\t\t\t\t<BodyText size=\"small\">\n\t\t\t\t\tEnable Opt-In or Opt-Out features to ensure that your emails\n\t\t\t\t\twill go to SPAM at a much lower rate.\n\t\t\t\t</BodyText>\n\t\t\t</Stack>\n\t\t\t<Button href=\"#\" iconName=\"chevron_right\" fill=\"subtle\" />\n\t\t</Stack>\n\t</Card>\n</Stack>\n")), "\n", React.createElement(_components.h3, {
    id: "example-a-configuration-this-is-stand-alone-and-doesnt-need-sorting-of-columns",
    style: {
      position: "relative"
    }
  }, "Example: A configuration, this is stand-alone and doesn’t need sorting of columns", React.createElement(_components.a, {
    href: "#example-a-configuration-this-is-stand-alone-and-doesnt-need-sorting-of-columns",
    "aria-label": "example a configuration this is stand alone and doesnt need sorting of columns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack direction=\"column\" spacing={2}>\n\t<Card>\n\t\t<Stack spacing={5} alignItems=\"center\">\n\t\t\t<Headline\n\t\t\t\tsize=\"small\"\n\t\t\t\tclassName=\"p-0 m-0\"\n\t\t\t\tstyle={{ minWidth: '200px' }}\n\t\t\t>\n\t\t\t\tDispatch Notification\n\t\t\t</Headline>\n\t\t\t<Form.Checkbox label=\"Text\" className=\"m-0\" />\n\t\t\t<Form.Checkbox checked label=\"Email\" className=\"m-0\" />\n\t\t\t<Form.Checkbox checked label=\"Tech Arrival\" className=\"m-0\" />\n\t\t</Stack>\n\t</Card>\n\t<Card>\n\t\t<Stack spacing={5} alignItems=\"center\">\n\t\t\t<Headline\n\t\t\t\tsize=\"small\"\n\t\t\t\tclassName=\"p-0 m-0\"\n\t\t\t\tstyle={{ minWidth: '200px' }}\n\t\t\t>\n\t\t\t\tReminder Notification\n\t\t\t</Headline>\n\t\t\t<Form.Checkbox checked label=\"Text\" className=\"m-0\" />\n\t\t\t<Form.Checkbox label=\"Email\" className=\"m-0\" />\n\t\t</Stack>\n\t</Card>\n</Stack>\n")), "\n", React.createElement(_components.h3, {
    id: "when-to-use-a-table-over-a-list-view",
    style: {
      position: "relative"
    }
  }, "When to use a Table over a list view", React.createElement(_components.a, {
    href: "#when-to-use-a-table-over-a-list-view",
    "aria-label": "when to use a table over a list view permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When rows of content are being directly compared against."), "\n", React.createElement(_components.li, null, "When users benefit from sorting the data to find their own patterns."), "\n", React.createElement(_components.li, null, "When there are 3+ columns of content in a list."), "\n", React.createElement(_components.li, null, "When column headers are necessary, particularly when columns should be sorted."), "\n", React.createElement(_components.li, null, "When little to no extra styling needs to be applied to cell content."), "\n"), "\n", React.createElement(_components.h3, {
    id: "when-to-use-a-list-view-over-a-table",
    style: {
      position: "relative"
    }
  }, "When to use a list view over a Table", React.createElement(_components.a, {
    href: "#when-to-use-a-list-view-over-a-table",
    "aria-label": "when to use a list view over a table permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When a row of content would make sense in isolation."), "\n", React.createElement(_components.li, null, "When the entire row is clickable, particularly around navigation."), "\n", React.createElement(_components.li, null, "When visual hierarchy needs to be applied to the list content."), "\n", React.createElement(_components.li, null, "When a grid view display of content would also make sense."), "\n"), "\n", React.createElement(_components.h1, {
    id: "how-to-use",
    style: {
      position: "relative"
    }
  }, "How to Use", React.createElement(_components.a, {
    href: "#how-to-use",
    "aria-label": "how to use permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "table-height--row-count",
    style: {
      position: "relative"
    }
  }, "Table Height & Row Count", React.createElement(_components.a, {
    href: "#table-height--row-count",
    "aria-label": "table height  row count permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "We recommend setting a ", React.createElement(_components.code, null, "height"), " of ", React.createElement(_components.code, null, "auto"), ", and a ", React.createElement(_components.code, null, "max-height"), " of 75% viewport height (", React.createElement(_components.code, null, "75vh"), ") for most tables. From there, setting an items per page count will create an additional max-height, whichever max height value is lower."), "\n", React.createElement(ImageBlock, {
    src: "/images/table/table_height_simulator_1.png"
  }), "\n", React.createElement(_components.p, null, "When few items are on the page, the table height will be as tall as there are items\navailable."), "\n", React.createElement("div", {
    className: "p-t-4"
  }), "\n", React.createElement(ImageBlock, {
    src: "/images/table/table_height_simulator_2.png"
  }), "\n", React.createElement(_components.p, null, "Viewport max-height will be contextual to the device. On a larger display, such as\nan external monitor, the max-height may be set around 17 or 18 cell items, while\nviewing it on a phone in landscape view may only produce 3 or 4 items in view."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Table\n\tstyle={{\n\t\theight: 'auto',\n\t\tmaxHeight: '75vh',\n\t}}\n\tstriped={false}\n\tdata={[\n\t\t{ id: 1, name: 'Chai', price: 18 },\n\t\t{ id: 2, name: 'Chang', price: 19 },\n\t\t{ id: 3, name: 'Aniseed Syrup', price: 10 },\n\t\t{ id: 4, name: 'Chef Anton’s Cajun Seasoning', price: 22 },\n\t]}\n>\n\t<TableColumn field=\"id\" title=\"Id\" width=\"60px\" />\n\t<TableColumn field=\"name\" title=\"Name\" />\n\t<TableColumn field=\"price\" title=\"Price\" width=\"180px\" format=\"{0:c}\" />\n</Table>\n")), "\n", React.createElement(_components.p, null, "Code example of height properties."), "\n", React.createElement(_components.h2, {
    id: "cell-alignment",
    style: {
      position: "relative"
    }
  }, "Cell Alignment", React.createElement(_components.a, {
    href: "#cell-alignment",
    "aria-label": "cell alignment permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In general, table cell content should be left-aligned horizontally, and vertically centered. Certain exceptions exist for numerical values, which can be right aligned."), "\n", React.createElement(Stack, {
    direction: "column",
    spacing: 4
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_cell_alignment.png"
  }), React.createElement(BodyText, null, React.createElement(_components.p, null, "An example of various alignments being applied properly. Some notable\ndetails include the filter icon alignment in right alignment, the center\nalignment applied to the 2 ID row, and bottom text alignment applied to\nmulti-column headers.")), React.createElement(BodyText, null, React.createElement(_components.p, null, "When using multi-column headers, the top header row text should be right\naligned when all of the children columns are right aligned (such as in\nthe above example), and left aligned in all other scenarios.")), React.createElement(Stack, {
    spacing: 3,
    className: "p-1"
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement("h3", null, "Left Aligned Items"), React.createElement("ul", null, React.createElement("div", null, React.createElement("li", null, "Text")), React.createElement("div", null, React.createElement("li", null, "ID")), React.createElement("div", null, React.createElement("li", null, "Date / Time / Total hours")), React.createElement("div", null, React.createElement("li", null, "Phone numbers")), React.createElement("div", null, React.createElement("li", null, "Zip codes")), React.createElement("div", null, React.createElement("li", null, "Invoice number")), React.createElement("div", null, React.createElement("li", null, "Tags")), React.createElement("div", null, React.createElement("li", null, "Icons")), React.createElement("div", null, React.createElement("li", null, React.createElement(_components.p, null, "Mix of numerical value and text (e.g. 2 invoices for\n$234)"))))), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement("h3", null, "Right Aligned Items"), React.createElement("ul", null, React.createElement("div", null, React.createElement("li", null, "$ amount")), React.createElement("div", null, React.createElement("li", null, "count / quantity")), React.createElement("div", null, React.createElement("li", null, "% percentage")))))), "\n", React.createElement(_components.h2, {
    id: "editing-experience",
    style: {
      position: "relative"
    }
  }, "Editing Experience", React.createElement(_components.a, {
    href: "#editing-experience",
    "aria-label": "editing experience permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "methods-to-start-editing",
    style: {
      position: "relative"
    }
  }, "Methods to start editing", React.createElement(_components.a, {
    href: "#methods-to-start-editing",
    "aria-label": "methods to start editing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "There are a few ways to prompt a user to modify entries on a table: by cell, and by row, with an option for a dedicated edit mode."), "\n", React.createElement(_components.h3, {
    id: "by-cell",
    style: {
      position: "relative"
    }
  }, "By Cell", React.createElement(_components.a, {
    href: "#by-cell",
    "aria-label": "by cell permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 4,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_cell_level_edit.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(_components.h3, {
    id: "by-row",
    style: {
      position: "relative"
    }
  }, "By Row", React.createElement(_components.a, {
    href: "#by-row",
    "aria-label": "by row permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 4,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_row_level_edit.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(_components.h3, {
    id: "edit-mode",
    style: {
      position: "relative"
    }
  }, "Edit Mode", React.createElement(_components.a, {
    href: "#edit-mode",
    "aria-label": "edit mode permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "m-t-4"
  }, React.createElement(Stack.Item, {
    grow: 4,
    basis: 0
  }, React.createElement(ImageBlock, {
    src: "/images/table/table_edit_mode.png"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  })), "\n", React.createElement(_components.p, null, "A dedicated edit mode can also be utilized when additional friction is needed to prevent unintentional edits, such as in business-critical situations. We do not recommend using an edit mode toggle if this friction is not useful."), "\n", React.createElement(_components.h3, {
    id: "ui-options-for-editing-phase",
    style: {
      position: "relative"
    }
  }, "UI Options for editing phase", React.createElement(_components.a, {
    href: "#ui-options-for-editing-phase",
    "aria-label": "ui options for editing phase permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "After choosing a method of editing, there are multiple UIs that could appear once a user starts to edit:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Inline editing via an Input or Select"), "\n", React.createElement(_components.li, null, "A drawer"), "\n", React.createElement(_components.li, null, "A dedicated page"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Inline editing")), "\n", React.createElement(_components.p, null, "Using the ", React.createElement(_components.a, {
    href: "(/components/inline-edit)"
  }, "Inline Edit"), " component, this is the simplest scenario, and is best used when using cell-level editing and the item being edited uses a simple control, such as an Input or Select."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "A Drawer")), "\n", React.createElement(_components.p, null, "Using a ", React.createElement(_components.a, {
    href: "/components/drawer"
  }, "Drawer"), " is useful with row-level editing. Some other reasons to use a Drawer:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When there are many editable fields."), "\n", React.createElement(_components.li, null, "You need to accommodate long and/or complex form fields"), "\n", React.createElement(_components.li, null, "You need to display additional information such as images, long descriptions etc.. without navigating users out of the original page."), "\n", React.createElement(_components.li, null, "Some of the editable fields are hidden from the current table view. (i.e., hidden columns)"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "A dedicated page")), "\n", React.createElement(_components.p, null, "This approach is suitable when there are many fields or complex interactions that demand more space and attention. You can use the screen real estate to display additional information such as images, long descriptions."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Content in cells should wrap instead of truncate, if there is enough room on the screen."), "\n", React.createElement(_components.li, null, "Avoid displaying data that is not relevant to the Table's context."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/components/tab/"
  }, "Tabs"), " can be used above a Table to filter clearly defined groups."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-components",
    style: {
      position: "relative"
    }
  }, "Related Components", React.createElement(_components.a, {
    href: "#related-components",
    "aria-label": "related components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "For a simpler display of data with less functionality, use a ", React.createElement(_components.a, {
    href: "/components/data-list"
  }, "Data List")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
